<template>
  <!-- 申请打包列表 -->
  <div class="container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="申请打包" name="first">
        <!-- 按钮列表 -->
        <div class="btnList1 wtl_btnList" ref="btnList">
          <div class="btnList1 wtl_btnList1">
            <el-button class="itemBtn btnColor" @click="rejection1"
              >创建51Track订单</el-button
            >
            <el-button class="itemBtn btnColor" @click="rejection2"
              >获取51Track物流轨迹</el-button
            >
            <el-button
              v-if="hasPermiss('bgrk_shenQingDaBao:add')"
              class="itemBtn btnColor"
              @click="NewReject"
              >新建打包申请
            </el-button>
            <el-button class="itemBtn btnColor" @click="rejection"
              >取消打包申请</el-button
            >
            <el-button
              v-if="hasPermiss('bgrk_shenQingDaBao:update')"
              class="itemBtn btnColor"
              @click="returnGoods"
              >修改
            </el-button>
            <el-button
              v-if="hasPermiss('bgrk_shenQingDaBao:suspend')"
              class="itemBtn btnColor"
              @click="suspend"
              >暂停打包
            </el-button>
            <el-button
              v-if="hasPermiss('bgrk_shenQingDaBao:allow')"
              class="itemBtn btnColor"
              @click="allow"
              >允许打包
            </el-button>
            <el-button
              v-if="hasPermiss('bgrk_shenQingDaBao:pickingPrint')"
              class="itemBtn btnColor"
              @click="printOrder"
              >打印拣货单</el-button
            >

            <el-button class="itemBtn btnColor" @click="downConfirm"
              >拣货下架</el-button
            >
            <el-button class="itemBtn btnColor" @click="canceldownConfirm"
              >取消下架</el-button
            >
            <el-button class="itemBtn btnColor" @click="daBaoGenZong"
              >客服跟踪</el-button
            >
            <!-- <el-button class="itemBtn btnColor" @click="aaaa">补录班次好</el-button> -->
            <el-button
              class="itemBtn btnColor wtl_mainBtns"
              @click="setBillChennelNumberV2Btn"
              >补录转运单</el-button
            >
            <el-button class="itemBtn btnColor" @click="PackingConfirmation"
              >打包确认</el-button
            >
            <el-button class="itemBtn btnColor" @click="PackingCancel"
              >取消打包确认</el-button
            >
            <el-button class="itemBtn btnColor" @click="EditConfirmation"
              >修改已打包单</el-button
            >
            <el-button
              class="itemBtn btnColor wtl_mainBtns2"
              @click="packDetails"
              >打包详情</el-button
            >
            <el-button class="itemBtn btnColor" @click="ExWarehouseBtns('1')"
              >出库确认</el-button
            >
            <el-button class="itemBtn btnColor" @click="ExWarehouseBtns('2')"
              >取消出库确认</el-button
            >
            <el-button
              class="itemBtn btnColor wtl_mainBtns"
              @click="packageInStockV2Btns"
              >运单入库</el-button
            >
            <el-button
              class="itemBtn btnColor wtl_mainBtns"
              @click="dispatchBillPackV2Btns"
              >派件确认</el-button
            >
            <el-button
              class="itemBtn btnColor wtl_mainBtns"
              @click="cancelDispatchBillPackV2"
              >取消派件</el-button
            >
            <el-button class="itemBtn btnColor" @click="qsBtns"
              >签收确认</el-button
            >
            <el-button class="itemBtn btnColor" @click="cancelSign"
              >取消签收</el-button
            >
            <el-button class="itemBtn btnColor" @click="goAddYiChang"
              >新建异常</el-button
            >

            <el-button class="itemBtn btnColor" @click="myModel"
              >表格设置</el-button
            >
            <el-dropdown @command="handleCommand_dc">
              <el-button class="dropdownBtn">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="dc">导出</el-dropdown-item>
                <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
                <el-dropdown-item command="scmb"
                  >保存(上传)模板</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <div style="width: 150px; height: 20px"></div>

            <!-- v-if="hasPermiss('bgrk_shenQingDaBao:query')" -->
            <div class="singleinpbtn wtl_queryBoxNew">
              <el-button
                class="seniorbut"
                @click="queryBtn_ok"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-tooltip content="查询项恢复初始状态" placement="top">
                <div class="condition" @click="resetFormQ(1)">清除</div>
              </el-tooltip>
              <el-tooltip content="查询更多条件" placement="top">
                <div class="condition" @click="more">
                  {{ MoreConditions }}
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>

        <!-- 查询条件框 -->
        <el-form :model="queryData">
          <div
            ref="queryCriteria"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <div class="singleinp_time">
                <div class="timeBox queryItemTime">
                  <el-select
                    class="queryInput"
                    v-model="timeType"
                    filterable
                    @change="timestatus"
                    placeholder="请选择时间类型"
                  >
                    <el-option
                      v-for="item in timeTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="right_inpu wtl_timeStyle">
                    <el-date-picker
                      class="input"
                      value-format="yyyy-MM-dd"
                      v-model="queryData.startTime"
                      type="date"
                      placeholder="选择日期"
                      @change="queryBtn_ok"
                    ></el-date-picker>
                    <div>至</div>
                    <el-date-picker
                      class="input"
                      value-format="yyyy-MM-dd"
                      v-model="queryData.endTime"
                      type="date"
                      placeholder="选择日期"
                      @change="queryBtn_ok"
                    ></el-date-picker>
                  </div>
                </div>
              </div>

              <div class="singleinp" @click="clickInput3">
                <div class="singleinpleft">打包批次号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="number1"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp" @click="clickInput5">
                <div class="singleinpleft">出库单号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="number5"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp" @click="clickInput4">
                <div class="singleinpleft">转运单号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="number4"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">团号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryData.invitationCode"
                    placeholder="请输入内容"
                    @keyup.enter.native="queryBtn_ok"
                  >
                  </el-input>
                </div>
              </div>
              <!-- <div class="singleinp">
                <div class="singleinpleft">会员:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryData.memberId"
                    placeholder="请输入内容"
                    @keyup.enter.native="queryBtn_ok"
                  >
                  </el-input>
                </div>
              </div> -->
              <div class="singleinp">
                <div class="singleinpleft">会员号:</div>
                <div class="singleinpright">
                  <el-select
                    class="inputs"
                    @change="huiayu($event, 1)"
                    v-model="queryData.memberId"
                    placeholder="请选择会员"
                    clearable
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="getMembers"
                    value-key="memberId"
                  >
                    <el-option
                      v-for="(item, index) in memberList"
                      :label="item.memberId"
                      :value="item.memberId + ''"
                      :key="index"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员名称:</div>
                <div class="singleinpright">
                  <el-select
                    class="inputs"
                    @change="huiayu($event, 2)"
                    v-model="queryData.memberName"
                    placeholder="请选择会员"
                    clearable
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="getMembersName"
                    value-key="memberId"
                  >
                    <el-option
                      v-for="(item, index) in memberList"
                      :label="item.nickname"
                      :value="item.nickname + ''"
                      :key="index"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">打包状态:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData.packStatus"
                    clearable
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in packageStatusArr"
                      :key="index"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- <div class="singleinp">
                <div class="singleinpleft">仓库:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    clearable
                    v-model="queryData.storageId"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in storageIdArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div> -->
              <div class="singleinp">
                <div class="singleinpleft">运输方式:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData.channelTransportId"
                    clearable
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in yunshuList"
                      :key="item.transportId"
                      :label="item.transportName"
                      :value="item.transportId"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </el-form>
        <!-- 表格 -->
        <!-- height  判断当表格超出某个高度的时候,固定高度,否则自动高度 具体的需要自己计算 -->
        <el-table
          :height="tableHeigth"
          ref="mytable"
          @row-click="rowclick"
          stripe
          :header-cell-style="{ background: '#f0f0f0' }"
          :data="tableData"
          border
          show-summary
          :summary-method="getSummaries"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed="left"
            align="center"
            label="序号"
            type="index"
            width="55"
          >
          </el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            type="selection"
            width="55"
          >
          </el-table-column>
          <template v-for="(item, index) in myTableHeard">
            <el-table-column
              :key="index"
              :label="item.name"
              :prop="item.field"
              sortable
              align="center"
              :show-overflow-tooltip="true"
              :min-width="item.width"
              :data-isTotal="item.isTotal"
              v-if="item && item.isShow"
            >
            </el-table-column>
          </template>
        </el-table>
        <sorttable
          :show_sortTableHeard="show_sortTableHeard"
          :allCheck="allCheck"
          :sortName="sortName"
          :tabelHeadeTitle="tabelHeadeTitle"
          :originalTabelHeadeTitle="originalTabelHeadeTitle"
          :selectLength="selectLength"
          @cancelSortHeard="cancelSortHeard"
          @confirmOk="confirmOk"
          @checktab="checktab"
          @checkAll="checkAll"
          @closeDias="closeDias"
        ></sorttable>
        <!-- 分页组件 -->
        <paging
          :pageNum="pageNum"
          :total="total"
          :sizeList="sizeList"
          :size="size"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        </paging>

        <el-dialog
          title="上传编辑好的导出模板"
          :visible.sync="show_upload"
          append-to-body
          width="40%"
          center
        >
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>

          <div slot="footer" class="dialog-footer">
            <el-button @click="show_upload = false">关 闭</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="申请打包明细" name="second">
        <!-- 按钮列表 -->
        <div class="btnList1" style="margin-top: 20px" ref="btnList">
          <el-button class="itemBtn btnColor" @click="myModel1"
            >表格设置</el-button
          >
          <el-dropdown @command="handleCommand_dc">
            <el-button class="dropdownBtn">
              导出<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="dc">导出</el-dropdown-item>
              <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
              <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 查询条件框 -->
        <el-form :model="queryDetails">
          <div
            ref="queryCriteria1"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <div class="singleinp" @click="clickInput">
                <div class="singleinpleft">快递单号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryDetails.courierNumber"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp" @click="clickInput2">
                <div class="singleinpleft">打包批次号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryDetails.billPackNumber"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryDetails.memberId"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">团号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryDetails.teamNumber"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">仓库:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryDetails.storageId"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in storageIdArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">收件人:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryDetails.consigneeName"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">打包状态:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryDetails.packageStatus"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in packageStatusArr"
                      :key="item.statusVal"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp1">
                <div class="singleinpleft">申请打包时间:</div>
                <div class="singleinpright">
                  <el-date-picker
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    @change="paymentTime2"
                    v-model="time2"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </div>
              <!-- <div class="singleinp">
                <div class="singleinpleft">起始日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    class="queryInput"
                    v-model="queryDetails.billPackStartTime"
                    value-format="yyyy-MM-dd"
                    type="datetime"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">结束日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    class="queryInput"
                    v-model="queryDetails.billPackEndTime"
                    value-format="yyyy-MM-dd"
                    type="datetime"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div> -->
            </div>
            <div class="item_left">
              <div class="singleinpbtn">
                <el-button
                  class="seniorbut"
                  @click="queryBtn_ok1"
                  icon="el-icon-search"
                  >查询</el-button
                >
                <el-tooltip content="查询项恢复初始状态" placement="top">
                  <div class="condition" @click="resetFormQ(2)">清除</div>
                </el-tooltip>
                <el-tooltip content="查询更多条件" placement="top">
                  <div class="condition" @click="more">
                    {{ MoreConditions }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </el-form>

        <!-- 表格 -->
        <el-table
          :height="tableHeigth"
          ref="mytable1"
          stripe
          :header-cell-style="{ background: '#f0f0f0' }"
          :data="tableData1"
          border
          show-summary
          :summary-method="getSummaries"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed="left"
            align="center"
            label="序号"
            type="index"
            width="55"
          ></el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            type="selection"
            width="55"
          ></el-table-column>
          <template v-for="(item, index) in myTableHeard1">
            <el-table-column
              :key="index"
              :label="item.name"
              :show-overflow-tooltip="true"
              align="center"
              :prop="item.field"
              :min-width="item.width"
              :data-isTotal="item.isTotal"
              v-if="item && item.isShow"
            >
            </el-table-column>
          </template>
        </el-table>
        <!-- 分页组件 -->
        <paging
          ref="pags"
          :pageNum="pageStart"
          :total="pageCount"
          :sizeList="sizeList"
          :size="pageTotal"
          @handleSizeChange="handleSizeChange2"
          @handleCurrentChange="handleCurrentChange2"
        >
        </paging>
        <!--   排序 -->
        <sorttable
          :show_sortTableHeard="show_sortTableHeard1"
          :allCheck="allCheck1"
          :sortName="sortName1"
          :tabelHeadeTitle="tabelHeadeTitle1"
          :originalTabelHeadeTitle="originalTabelHeadeTitle1"
          :selectLength="selectLength1"
          @cancelSortHeard="cancelSortHeard1"
          @confirmOk="confirmOk1"
          @checktab="checktab1"
          @checkAll="checkAll1"
          @closeDias="closeDias1"
        ></sorttable>
        <el-dialog
          title="上传编辑好的导出模板"
          :visible.sync="show_upload"
          append-to-body
          width="40%"
          center
        >
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>

          <div slot="footer" class="dialog-footer">
            <el-button @click="show_upload = false">关 闭</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>

    <!-- 弹窗 -->
    <!-- 打包单取消派件V2 -->
    <el-dialog
      title="取消派件"
      :visible.sync="dislogShow_1"
      append-to-body
      width="40%"
      center
      @closed="closeBtn_1"
    >
      <div class="diaBox">
        <h3>已选需要取消派件的打包单数量: {{ singleData.length }}个</h3>
        <div class="textareaBox">
          <div class="labelClass">备注:</div>
          <el-input
            class="textareaInput"
            type="textarea"
            v-model="diaCommon"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_1">确认取消派件</el-button>
      </div>
    </el-dialog>
    <!-- 补录转运单 -->
    <el-dialog
      title="补录转运单"
      :visible.sync="dislogShow_2"
      append-to-body
      width="40%"
      center
      @closed="closeBtn_1"
    >
      <div class="diaBox">
        <div class="textareaBox">
          <div class="labelClass">转运单号:</div>
          <el-input
            class="textareaInput"
            v-model="diaCommon"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_2">保存</el-button>
      </div>
    </el-dialog>

    <!-- 取消出库 -->
    <el-dialog
      title="取消出库"
      :visible.sync="dislogShow_3"
      append-to-body
      width="40%"
      center
      @closed="closeBtn_1"
    >
      <div class="diaBox">
        <h3>已选需要取消出库的打包单数量: {{ singleData.length }}个</h3>
        <div class="textareaBox">
          <div class="labelClass">备注:</div>
          <el-input
            class="textareaInput"
            type="textarea"
            v-model="diaCommon"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_3">确认取消出库</el-button>
      </div>
    </el-dialog>

    <!-- 出库确认 -->
    <el-dialog
      title="出库确认"
      :visible.sync="dislogShow_4"
      append-to-body
      width="40%"
      center
      @closed="closeBtn_1"
    >
      <div class="diaBox">
        <h3>已选需要出库确认的打包单数量: {{ singleData.length }}个</h3>
        <div class="textareaBox">
          <div class="labelClass">备注:</div>
          <el-input
            class="textareaInput"
            type="textarea"
            v-model="diaCommon"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_4">确认出库</el-button>
      </div>
    </el-dialog>

    <!-- 运单入库 -->
    <el-dialog
      title="运单入库"
      :visible.sync="dislogShow_5"
      append-to-body
      width="40%"
      center
      @closed="closeBtn_2"
    >
      <div class="diaBox">
        <h3>已选入库打包单数量: {{ singleData.length }}个</h3>
        <div class="textareaBox">
          <div class="labelClass">入库仓库:</div>
          <el-select
            class="queryInput"
            v-model="diaCommon"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in storageIdArr"
              :key="item.id"
              :label="item.storageName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="textareaBox">
          <div class="labelClass">备注:</div>
          <el-input
            class="textareaInput"
            type="textarea"
            v-model="diaCommon2"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_5">确认入库</el-button>
      </div>
    </el-dialog>
    <!-- 派件确认 -->
    <el-dialog
      title="派件确认"
      :visible.sync="dislogShow_6"
      append-to-body
      width="40%"
      center
      @closed="closeBtn_2"
    >
      <div class="diaBox">
        <h3>已选打包单数量: {{ singleData.length }}个</h3>
        <div class="textareaBox">
          <div class="labelClass">派件员:</div>
          <el-select
            class="queryInput"
            v-model="diaCommon"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in yonghuList"
              :key="index"
              :label="item.employeeName"
              :value="item.employeeId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="textareaBox">
          <div class="labelClass">备注:</div>
          <el-input
            class="textareaInput"
            type="textarea"
            v-model="diaCommon2"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_6">确认派件</el-button>
      </div>
    </el-dialog>

    <!-- 输入快递单号(打包明细) -->
    <inputnumber
      :showInputBox="showInputBox"
      :inputNumbers="queryDetails.courierNumber"
      titles="输入快递单号"
      @showInputBoxBtn="showInputBoxBtn"
      @closeDiasInputVal="closeDiasInputVal"
    ></inputnumber>
    <!-- 输入批次号(打包明细) -->
    <inputnumber
      :showInputBox="showInputBox2"
      :inputNumbers="queryDetails.billPackNumber"
      titles="输入打包批次号"
      @showInputBoxBtn="showInputBoxBtn2"
      @closeDiasInputVal="closeDiasInputVal2"
    ></inputnumber>
    <!-- 输入批次号(申请打包) -->
    <inputnumber
      :showInputBox="showInputBox3"
      :inputNumbers="queryDetails.billPackNumber"
      titles="输入打包批次号"
      @showInputBoxBtn="showInputBoxBtn3"
      @closeDiasInputVal="closeDiasInputVal3"
    ></inputnumber>
    <!-- 转单号(申请打包) -->
    <inputnumber
      :showInputBox="showInputBox4"
      :inputNumbers="queryDetails.billPackNumber"
      titles="输入转单号"
      @showInputBoxBtn="showInputBoxBtn4"
      @closeDiasInputVal="closeDiasInputVal4"
    ></inputnumber>
    <!-- 出库单号(申请打包) -->
    <inputnumber
      :showInputBox="showInputBox5"
      :inputNumbers="queryDetails.billPackNumber"
      titles="输入出库单号"
      @showInputBoxBtn="showInputBoxBtn5"
      @closeDiasInputVal="closeDiasInputVal5"
    ></inputnumber>

    <!-- 取消下架 -->
    <div class="QXXJ_Container">
      <el-dialog
        title="取消下架"
        center
        width="40%"
        :visible.sync="isDT_QXXJ"
        :before-close="closebut"
      >
        <div class="tips">
          <div class="msg">是否对以下批次号进行取消下架？</div>
          <div class="numbox">
            <div>打包批次号：</div>
            <div>{{ applyPackNumber }}</div>
          </div>
        </div>

        <div class="tableB">
          <el-table
            :header-cell-style="{ background: '#f0f0f0' }"
            stripe
            border
            :data="downframeList"
            style="width: 90%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="courierNumber"
              align="center"
              min-width="160"
              label="快递单号"
            ></el-table-column>
          </el-table>
        </div>
        <div class="endFooter">
          <div>包裹合计：{{ downframeList.length }}件</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="warning" size="mini" @click="cancenDownFrame"
            >确 认</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- 取消签收 -->
    <el-dialog
      title="取消签收"
      :visible.sync="cancel"
      width="40%"
      @close="cancelSignC"
    >
      <div class="titlebox">
        是否对以下{{ singleData.length }}个打包批次号进行取消签收?
      </div>
      <div class="formbox">
        <el-form
          label-position="right"
          label-width="100px"
          :model="formLabelAlign"
        >
          <el-form-item label="打包批次号:">
            <el-input
              type="textarea"
              v-model="formLabelAlign.storePackNum"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="取消签收时间:">
            <el-input v-model="formLabelAlign.dateTime" readonly></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="cancelSignSub">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建客服跟踪 -->
    <addCustomerTarck
      :showDia1="showDia1"
      :documentNumbers="documentNumbers"
      :documentStatus="documentStatus"
      :isdisabledStatus="true"
      @confirmAdd="confirmAdd"
      @closeDia1="closeDia1"
    ></addCustomerTarck>

    <!-- 创建51订单 -->
    <el-dialog
      :title="title7"
      :visible.sync="dislogShow_7"
      append-to-body
      width="40%"
      center
      @closed="closeBtn_7"
    >
      <div class="diaBox">
        <div class="textareaBox">
          <div class="labelClass">快递公司:</div>
          <el-select
            v-model="newForm7.courierCode"
            filterable
            placeholder="请选择快递公司"
            clearable
            remote
            :remote-method="(val) => myGetExpressChooseList(true, val)"
            @visible-change="myGetExpressChooseList($event, val)"
          >
            <el-option
              v-for="(item, index) in expressList"
              :label="item.expressName"
              :value="item.expressNumber"
              :key="index"
            >
            </el-option>
          </el-select>
        </div>
        <div class="textareaBox">
          <div class="labelClass">快递单号:</div>
          <el-input
            v-model="newForm7.trackingNumber"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="textareaBox">
          <div class="labelClass">客户名称:</div>
          <el-input
            disabled
            v-model="newForm7.customerName"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_7">确认派件</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import addCustomerTarck from "@/components/CustomerServiceTrackingNew.vue";
import inputnumber from "@/components/inputNumber.vue";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
    inputnumber,
    addCustomerTarck,
  },
  data() {
    return {
      type51: "",
      expressList: [], //  快递公司列表
      newForm7: {
        courierCode: "",
        customerName: "",
        trackingNumber: "",
      },
      number1: "", //打包批次号
      number4: "", //转单号
      number5: "", //转单号
      // 输入快递单号弹窗
      showInputBox: false, //
      showInputBox2: false, //
      showInputBox3: false, //
      showInputBox4: false, //
      showInputBox5: false, //
      activeName: "first",
      queryData: {
        startTime: "",
        endTime: "",
        billPackNumbers: "",
        channelNumbers: "",
        channelTransportId: "",
        endBillPackTime: "",
        startBillPackTime: "",
        startFinishPackTime: "",
        startSignForTime: "",
        endFinishPackTime: "",
        endSignForTime: "",
        packStatus: "",
        outStockNumbers: "",
        memberId: "",
        invitationCode: "",
      },
      queryData1: {},

      // ======公用组件==========
      pageNum: 1, //页码
      pageNum2: 1, //页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      size2: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_sortTableHeard1: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      originalTabelHeadeTitle1: [],
      myTableHeard1: [], //我的表头
      selectLength: 0, //显示的长度
      selectLength1: 0, //显示的长度
      allCheck: false, //全选
      allCheck1: false, //全选
      tableHeigth: "50Vh",
      tabelHeadeTitle: [
        {
          name: "打包批次号",
          field: "billPackNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "出库单号",
          field: "billStorepackNumber",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包单号",
          field: "billClosePackageNumber",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "客户单号",
          field: "customerNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "提货方式",
          field: "handoverModeShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "商店代号",
          field: "storeIdNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "分店名称",
          field: "storeName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "门店地址",
          field: "storeAddress",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "在库状态",
          field: "stockStatusShow",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包状态",
          field: "packStatusShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "当前仓库",
          field: "currentStorageName",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包仓库",
          field: "storageName",
          width: "140",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "运输方式",
          field: "transportName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "单据类型",
          field: "billPackTypeShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "规格",
          field: "specs",
          width: "140",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "品名",
          field: "itemName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickname",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "团号",
          field: "invitationCode",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包备注",
          field: "packComment",
          width: "180",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件人姓名",
          field: "fullName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件人电话",
          field: "phone",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件国家/地区",
          field: "areaCountryName",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货邮编",
          field: "postalCode",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货城市",
          field: "cityName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货详细地址",
          field: "address",
          width: "180",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "渠道名称",
          field: "channelName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "渠道代理",
          field: "channeAgentlName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "转运单号",
          field: "channelNumber",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "班次号",
          field: "shiftNumber",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包单总重量(kg)",
          field: "totalWeight",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "打包单总体积(m³)",
          field: "totalVolume",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "打包单总件数",
          field: "totalCount",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "打包金额",
          field: "freight",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "打包申请时间",
          field: "packTime",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "applicantUserName",
          width: "80",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包完成时间",
          field: "finishPackTime",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包人",
          field: "finishPackUserName",
          width: "80",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "签收时间",
          field: "userSignForTime",
          width: "160",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tabelHeadeTitle1: [
        {
          name: "快递单号",
          field: "courierNumber",
          width: "80",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "快递公司",
          field: "expressName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包批次号",
          field: "billPackNumber",
          width: "180",
          sort: 16,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库",
          field: "storageName",
          width: "80",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "memberName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "转单号",
          field: "channelNumber",
          width: "180",
          sort: 17,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "货物品类",
          field: "itemcategoryName",
          width: "80",
          sort: 11,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "品名",
          field: "itemName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包裹件数",
          field: "packageCount",
          width: "80",
          sort: 12,
          isShow: true,
          isTotal: true,
          isSort: true,
        },

        {
          name: "包裹重量(KG)",
          field: "packageWeight",
          width: "80",
          sort: 13,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "包裹体积(m³)",
          field: "packageVolume",
          width: "80",
          sort: 14,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "长(cm)",
          field: "packageLength",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "宽(cm)",
          field: "packageWidth",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "高(cm)",
          field: "packageHeight",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包裹备注",
          field: "packageComment",
          width: "80",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "打包时间",
          field: "packTime",
          width: "80",
          sort: 22,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包人",
          field: "packerUserName",
          width: "80",
          sort: 22,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "团号",
          field: "teamNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      tableData1: [],
      totalArr: [], //需要合计的字段
      totalArr1: [], //需要合计的字段
      className: "", //导出需要
      className1: "",
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      sortName1: "模板名", //排序名
      pageGroupName: "ApplicationPackaging", //页面标识
      //    --------- 一条华丽的分割线  ---------
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      singleData: [], //  编辑数据
      //    --------- 申请打包明细列表  ---------
      queryDetails: {
        courierNumber: "",
        billPackNumber: "",
        memberId: "",
        teamNumber: "",
        storageId: "",
        consigneeName: "",
        packageStatus: "",
        billPackStartTime: "",
        billPackEndTime: "",
      }, //  搜索数据表单
      storageIdArr: [], //  仓库下拉列表
      yunshuList: [], //运输下拉列表
      packageStatusArr: [], //  包裹状态下拉列表
      memberList: [], //会员下拉列表
      pageStart: 1, //  分页配置
      pageCount: 0,
      pageTotal: 50,

      time: [],
      time2: [],
      timeType: "1",
      timeTypes: [
        //  时间类型
        {
          value: "0",
          label: "打包完成时间",
        },
        {
          value: "1",
          label: "打包申请时间",
        },
        {
          value: "2",
          label: "签收时间",
        },
      ],

      // 弹窗
      dislogShow_1: false, //取消派件
      dislogShow_2: false, //补录转运单
      dislogShow_3: false, //取消出库
      dislogShow_4: false, //出库确认
      dislogShow_5: false, //运单入库
      dislogShow_6: false, //派件确认
      dislogShow_7: false, //创建订单 查物流
      diaCommon: "", //弹窗备注
      diaCommon2: "",

      // 取消下架
      isDT_QXXJ: false,
      applyPackNumber: "",
      downframeList: [],

      yonghuList: [], //管理员列表
      cancel: false,
      formLabelAlign: {
        storePackIds: [],
        storePackNum: "",
        dateTime: "",
      },

      showDia1: false,
      documentNumbers: "",
      documentStatus: "",
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
    if (this.show_sortTableHeard1) {
      // 排序表头
      let arr = this.tabelHeadeTitle1;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle1 = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    if (this.originalTabelHeadeTitle1.length == 0) {
      this.originalTabelHeadeTitle1 = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //获取用户自定义表头
    this.myGetStorageList(); //  获取仓库下拉列表
    this.getStatusValList(); //  获取包裹状态
    let endtime = tools.getCurrentDate(); //今天的时间;
    let sarttime = tools.getFlexDate(-10); //10天前
    // this.time = [sarttime, endtime];
    // this.time2 = [sarttime, endtime];

    this.queryData.startTime = sarttime;
    this.queryData.endTime = endtime;

    // this.queryDetails.billPackStartTime = sarttime + " 00:00:00";
    // this.queryDetails.billPackEndTime = endtime + " 23:59:59";
  },
  mounted() {
    this.getData();
    this.getTransportMethodList();
    this.getVipList();
    this.adminUserList();
    this.myGetExpressChooseList();
  },
  watch: {},
  methods: {
    myGetExpressChooseList(e, val) {
      if (!e) {
        return false;
      }

      //  快递公司下拉列表
      Api.expressChooseList({
        expressName: val,
      }).then((res) => {
        if (res.data.status === "success") {
          this.expressList = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--快递公司下拉列表`);
        }
      });
    },
    daBaoGenZong() {
      let str = "";
      this.singleData.forEach((item) => {
        str += item.billPackNumber + ",";
      });
      if (str.length > 0) {
        str = str.substring(0, str.length - 1);
      }
      this.documentNumbers = str;
      this.documentStatus = "bill_pack_v1:document_status"; //申请打包单据
      this.showDia1 = true;
    },
    // 提交新建
    confirmAdd(formName) {
      this.showDia1 = false;
      this.getData();
    },
    // 关闭重置弹窗数据
    closeDia1(e) {
      this.showDia1 = e || false;
      this.documentNumbers = "";
    },

    // 管理员/派件员列表
    adminUserList() {
      let param = {
        employeeName: "",
        pageStart: 1,
        pageTotal: 200,
      };
      // Api.adminUserList(param).then((res) => {
      //   this.yonghuList = res.data.result.data || [];
      // });
      Api.userEmployeeList(param).then((res) => {
        this.yonghuList = res.data.result.data || [];
      });
    },
    // 打包单取消派件
    cancelDispatchBillPackV2() {
      if (this.singleData.length < 1) {
        this.$message.warning("请至少选择一条打包单!");
        return false;
      }
      this.dislogShow_1 = true;
    },
    // 关闭弹窗-
    closeBtn_1() {
      this.diaCommon = "";
    },
    // 关闭弹窗-入库
    closeBtn_2() {
      this.diaCommon = "";
      this.diaCommon2 = "";
    },
    // 确定取消派件
    confirmBtn_1() {
      let ids = [];
      this.singleData.forEach((item) => {
        ids.push(item.billPackId);
      });
      let params = {
        billPackId: ids || [],
        comment: this.diaCommon || "",
      };
      params.sign = tools.getSign(params);
      Api.cancelDispatchBillPackV2(params).then((res) => {
        this.dislogShow_1 = false;
        this.$message.success(res.data.message || "操作成功");
        this.getData();
      });
    },

    // 保存补录转运单
    confirmBtn_2() {
      let that = this;
      let ids = [];
      this.singleData.forEach((item) => {
        ids.push(item.billPackId);
      });
      let param = {
        billPackId: ids || [],
        channelNumber: this.diaCommon || "",
      };
      param.sign = tools.getSign(param);
      Api.setBillChennelNumberV2(param).then((res) => {
        this.$message.success(res.data.message || "补录成功");
        setTimeout(() => {
          that.dislogShow_2 = false;
          that.getData();
        }, 500);
      });
    },

    // 保存取消出库
    confirmBtn_3() {
      let that = this;
      let ids = [];
      this.singleData.forEach((item) => {
        ids.push(item.billPackId);
      });
      let param = {
        billPackId: ids || [],
        channelNumber: this.diaCommon || "",
      };
      param.sign = tools.getSign(param);
      Api.setBillChennelNumberV2(param).then((res) => {
        this.$message.success(res.data.message || "补录成功");
        setTimeout(() => {
          that.dislogShow_3 = false;
          that.getData();
        }, 500);
      });
    },
    // 保存出库确认
    confirmBtn_4() {
      let that = this;
      let ids = [];
      this.singleData.forEach((item) => {
        ids.push(item.billPackId);
      });
      let param = {
        billPackId: ids || [],
        comment: this.diaCommon || "",
      };
      param.sign = tools.getSign(param);
      Api.packPackageOutStockV2(param).then((res) => {
        this.$message.success(res.data.message || "出库成功");
        setTimeout(() => {
          that.dislogShow_4 = false;
          that.getData();
        }, 500);
      });
    },

    // 保存入库
    confirmBtn_5() {
      let that = this;
      let ids = [];
      this.singleData.forEach((item) => {
        ids.push(item.billPackId);
      });
      let param = {
        billPackId: ids || [],
        storageId: this.diaCommon || "",
        comment: this.diaCommon2 || "",
      };
      param.sign = tools.getSign(param);
      Api.packageInStockV2(param).then((res) => {
        this.$message.success(res.data.message || "入库成功");
        setTimeout(() => {
          that.dislogShow_5 = false;
          that.getData();
        }, 500);
      });
    },
    // 保存派件确认
    confirmBtn_6() {
      let that = this;
      let ids = [];
      this.singleData.forEach((item) => {
        ids.push(item.billPackId);
      });
      let param = {
        billPackId: ids || [],
        dispatchUserId: this.diaCommon || "",
        comment: this.diaCommon2 || "",
      };
      param.sign = tools.getSign(param);
      Api.dispatchBillPackV2(param).then((res) => {
        this.$message.success(res.data.message || "入库成功");
        setTimeout(() => {
          that.dislogShow_6 = false;
          that.getData();
        }, 500);
      });
    },

    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    //   ==================会员下拉选择模块================
    huiayu(e, num) {
      if (!e) {
        this.getVipList();
        this.queryData.memberId = "";
        this.queryData.memberName = "";
        return false;
      }
      if (num == 1) {
        this.memberList.forEach((item) => {
          if (item.memberId == e) {
            this.queryData.memberName = item.nickname;
          }
        });
      }
      if (num == 2) {
        this.memberList.forEach((item) => {
          if (item.nickname == e) {
            this.queryData.memberId = item.memberId;
          }
        });
      }
      this.queryBtn_ok();
    },
    // 远程搜索会员
    getMembers(e) {
      if (e.length > 0) {
        this.getVipList(e);
      } else {
        this.getVipList();
      }
    },
    // 远程搜索会员名
    getMembersName(e) {
      if (e.length > 0) {
        this.getVipList(e, "name");
      } else {
        this.getVipList();
      }
    },
    getVipList(e, type) {
      let val = e || "";
      Api.getMemberDownList({
        memberId: type ? "" : val,
        nickname: type == "name" ? val : "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
          // this.total = res.data.result.pageCount || 0;
        }
      });
    },

    //   ==================会员下拉选择模块================

    // 请选择时间类型
    timestatus(e) {
      console.log(e);
      let Timedata = this.Timedata;
      Timedata.pageStart = this.homePage;
      Timedata.pageTotal = this.articless;
      // if (e) {
      if (e == "0") {
        //   打包完成时间
        this.timelist = [];
        this.timelistdata = [];
      } else if (e == "1") {
        //   打包申请时间
        this.timelist = [];
        this.timelistdata = [];
      }
    },

    //时间
    paymentTime2(e) {
      if (e && e.length > 0) {
        this.queryDetails.billPackStartTime = e[0] + " " + "00:00:00";
        this.queryDetails.billPackEndTime = e[1] + " " + "23:59:59";
      } else {
        this.queryDetails.billPackStartTime = "";
        this.queryDetails.billPackEndTime = "";
      }
      this.queryBtn_ok1();
    },

    //点击输入快递单号 (打包明细)
    clickInput() {
      this.showInputBox = true;
    },
    showInputBoxBtn(val) {
      this.showInputBox = false;
      this.queryDetails.courierNumber = val;
      this.packPackageDescs();
    },
    closeDiasInputVal(val) {
      this.showInputBox = val || false;
    },
    //点击输入批次号(打包明细)
    clickInput2() {
      this.showInputBox2 = true;
    },
    showInputBoxBtn2(val) {
      this.showInputBox2 = false;
      this.queryDetails.billPackNumber = val;
      this.packPackageDescs();
    },
    closeDiasInputVal2(val) {
      this.showInputBox2 = false;
    },
    //点击输入批次号(申请打包)
    clickInput3() {
      this.showInputBox3 = true;
    },
    showInputBoxBtn3(val) {
      this.showInputBox3 = false;
      this.number1 = val;
      this.getData();
    },
    closeDiasInputVal3(val) {
      this.showInputBox3 = false;
    },
    //转单号(申请打包)
    clickInput4() {
      this.showInputBox4 = true;
    },
    showInputBoxBtn4(val) {
      this.showInputBox4 = false;
      this.number4 = val;
      this.getData();
    },
    closeDiasInputVal4(val) {
      this.showInputBox4 = false;
    },
    //出库单号(申请打包)
    clickInput5() {
      this.showInputBox5 = true;
    },
    showInputBoxBtn5(val) {
      this.showInputBox5 = false;
      this.number5 = val;
      this.getData();
    },
    closeDiasInputVal5(val) {
      this.showInputBox5 = false;
    },

    //获取运输方式
    getTransportMethodList() {
      Api.getTransportMethodList().then((res) => {
        this.yunshuList = res.data.result.data || [];
      });
    },
    more() {
      //输入框绑定动态class
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },
    // 打印拣货单
    printOrder() {
      if (this.singleData.length < 1) {
        this.$message.warning("请至少选择一条打包单!");
        return;
      }
      // console.log(this.singleData)
      let ids = [];
      this.singleData.forEach((item) => {
        ids.push(item.billPackId);
      });
      // console.log(ids)
      this.$router.push({
        path: "/BusinessManagement/Warehousing/PrintPickingList",
        query: {
          ids: JSON.stringify(ids),
        },
      });
    },

    //xxx
    aaaa() {},

    // 打包单详情
    packDetails() {
      if (this.singleData.length !== 1) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      this.$router.push({
        path: "/pages/PackagingDetails_new",
        query: {
          packageId: this.singleData[0].billPackId,
        },
      });
    },

    // 打包确认
    PackingConfirmation() {
      if (this.singleData.length === 1) {
        this.$router.push({
          path: "/BusinessManagement/ExWarehouse/PackingConfirmation",
          query: {
            id: this.singleData[0].billPackId,
          },
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },
    // 取消确认
    PackingCancel() {
      let that = this;
      // console.log(this.singleData)
      if (this.singleData.length === 1) {
        if (this.singleData[0].packStatus != "yi_wan_cheng:pack_status") {
          this.$message.warning("该包裹还没打包确认,无须取消确认");
          return;
        }
        this.$confirm("是否取消确认当前包裹?", "提示")
          .then(() => {
            let param = {
              billStorepackId: this.singleData[0].billStorepackId || "", //仓库打包单id
              billStorepackNumber: this.singleData[0].billStorepackNumber || "", //仓库打包单编号
            };
            let sign = tools.getSign(param);
            param.sign = sign;
            Api.cancelPackStorage(param).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "");
                that.getData();
              }
            });
          })
          .catch(() => {});
      } else if (this.singleData.length > 1) {
        this.$message.warning("一次只能修改一条数据");
      } else if (this.singleData.length < 1) {
        this.$message.warning("请选择一条数据");
      }
    },
    // 修改打包单
    EditConfirmation() {
      if (this.singleData.length === 1) {
        if (this.singleData[0].packStatus === "yi_wan_cheng:pack_status") {
          this.$router.push({
            path: "/BusinessManagement/ExWarehouse/EditPackingConfirmation",
            query: {
              id: this.singleData[0].billPackId,
            },
          });
        } else {
          this.$message.warning("请选择状态为已完成打包的单号");
        }
      } else {
        this.$message.warning("请选择一条数据");
      }
    },

    //  出库确认&取消出库
    ExWarehouseBtns(num) {
      let _this = this;
      // let btnType = _this.Tools.isDaiGou() || false;
      let btnType = _this.Tools.NoHuoMao() || false;
      console.log("btnType");
      console.log(btnType);
      if (this.singleData.length > 0) {
        if (num == "1") {
          // 出库确认
          _this.dislogShow_4 = true;
        } else if (num == "2") {
          // 取消出库
          // _this.dislogShow_3 = true;

          this.$confirm(`取消出库数量${this.singleData.length}个`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let param = {
              storepackId: [],
            };
            this.singleData.forEach((item) => {
              param.storepackId.push(item.billStorepackId);
            });
            if (btnType) {
              param.comment = "";
              param.sign = tools.getSign(param);
              console.log("param");
              console.log(param);
              Api.cancelOutStockV2(param).then((res) => {
                if (res.data.status === "success") {
                  this.$message.success("取消出库成功");
                  _this.getData();
                }
              });
              // Api.bmg_cancelOutStock(param).then((res) => {
              //   if (res.data.status === "success") {
              //     this.$message.success("取消出库成功");
              //     _this.getData();
              //   }
              // });
            } else {
              Api.cancelOutStock(param).then((res) => {
                if (res.data.status === "success") {
                  this.$message.success("取消出库成功");
                  _this.getData();
                }
              });
            }
          });
        }
      } else {
        this.$message.warning("请至少勾选一条数据");
      }
    },

    // 运单入库按钮
    packageInStockV2Btns() {
      if (this.singleData.length < 1) {
        this.$message.error("请至少选择一个打包单");

        return false;
      } else {
        this.dislogShow_5 = true;
      }
    },
    // 派件确认按钮
    dispatchBillPackV2Btns() {
      if (this.singleData.length < 1) {
        this.$message.error("请至少选择一个打包单");

        return false;
      } else {
        this.dislogShow_6 = true;
      }
    },

    //取消下架
    canceldownConfirm() {
      if (this.singleData.length < 1) {
        this.$message.error("请选择一个打包批次号");
        return;
      } else if (this.singleData.length > 1) {
        this.$message.error("一次只能选择一个包裹");
        return;
      }

      console.log(this.singleData[0]);
      this.applyPackNumber = this.singleData[0].billPackNumber;
      Api.cancelPickDownDetail({
        billpackId: this.singleData[0].billPackId,
      }).then((res) => {
        this.downframeList = res.data.result || [];
        this.isDT_QXXJ = true;
      });
    },
    // 确定取消下架
    cancenDownFrame() {
      let param = new Object();
      let cNumArr = [];
      let dflist = this.downframeList || [];
      dflist.forEach((item) => {
        cNumArr.push(item.courierNumber);
      });

      param = {
        billPackNumber: this.applyPackNumber, //申请打包单好
        courierNumbers: cNumArr, //快递单号
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      //  取消下架
      Api.cancelDownframe(param).then((res) => {
        if (res.data.status === "success") {
          this.$message.success(res.data.message || "取消成功");
          this.isDT_QXXJ = false;
          this.getData();
        } else {
          this.$message.error(`${res.data.message}--取消下架`);
        }
      });
    },
    //取消下架关闭弹窗
    closebut() {
      this.isDT_QXXJ = false;
      this.downframeList = [];
      this.applyPackNumber = "";
    },
    //  拣货下架
    downConfirm() {
      if (this.singleData.length === 1) {
        this.$router.push({
          path: "/BusinessManagement/ExWarehouse/DownConfirm",
          query: {
            id: this.singleData[0].billPackId,
          },
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },
    //  补录转单号
    setBillChennelNumberV2Btn() {
      if (this.singleData.length > 0) {
        this.dislogShow_2 = true;
      } else {
        this.$message.warning("请选择至少一条数据");
      }
    },

    // 获取当前时间
    myGetTime() {
      let _date = "";
      let myDate = new Date();
      myDate.getFullYear(); //获取完整的年份
      myDate.getMonth() + 1; //获取当前月份
      myDate.getDate(); //获取当前日(1-31)
      _date = `${myDate.getFullYear()}-${
        myDate.getMonth() + 1
      }-${myDate.getDate()}`;
      return _date;
    },
    //  签收确认
    qsBtns() {
      if (this.singleData.length > 0) {
        //console.log(this.singleData)
        let idArr = [];
        this.singleData.forEach((item) => {
          idArr.push(item.billStorepackId);
        });
        this.$confirm(
          `即将签收${
            this.singleData.length
          }个包裹, 签收时间：${this.myGetTime()}?`,
          "提示",
          {
            confirmButtonText: "签收确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          Api.signForConfirm({ storePackIds: idArr }).then((res) => {
            if (res.data.status === "success") {
              this.singleData = [];
              this.getData();
              this.$message.success("签收成功");
            }
          });
        });
      } else {
        this.$message.error("请至少选择一条数据");
      }
    },

    goAddYiChang() {
      this.$router.push({
        path: "/exceptionhandling/Newexception",
      });
    },

    //  取消签收
    cancelSign() {
      if (this.singleData.length > 0) {
        let arr = [];
        let billPackNumberStr = "";
        this.singleData.forEach((item) => {
          arr.push(item.billStorepackId);
          billPackNumberStr += item.billPackNumber + ",";
        });
        if (billPackNumberStr.length > 1) {
          billPackNumberStr = billPackNumberStr.substring(
            0,
            billPackNumberStr.lastIndexOf(",")
          );
        }
        this.formLabelAlign.storePackIds = arr;
        this.formLabelAlign.dateTime = this.myGetTime();
        this.formLabelAlign.storePackNum = billPackNumberStr;
        this.cancel = true;
      } else {
        this.$message.error("请选择一条数据");
      }
    },
    //  取消签收弹出层关闭的回调
    cancelSignC() {
      this.formLabelAlign = {
        storePackIds: [],
        storePackNum: "",
        dateTime: "",
      };
      this.cancel = false;
    },
    //  确认取消签收
    cancelSignSub() {
      Api.cancelSignFor({
        storePackIds: this.formLabelAlign.storePackIds,
      }).then((res) => {
        if (res.data.status === "success") {
          this.$message.success("取消签收成功");
          this.cancel = false;
          this.getData();
        }
      });
    },

    getData() {
      //  获取数据
      let courierNumberList = tools.getOrderNum(this.number1) || []; //打包批次号数组
      let channelNumbers = tools.getOrderNum(this.number4) || []; //转单号
      let number5 = tools.getOrderNum(this.number5) || []; //出库单号

      let startBillPackTime = ""; //打包申请
      let endBillPackTime = "";
      let startFinishPackTime = ""; //打包完成
      let endFinishPackTime = "";
      let startSignForTime = ""; //签收时间
      let endSignForTime = "";

      let startTime = this.queryData.startTime;
      let endTime = this.queryData.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }

      if (this.timeType == "1") {
        // 打包申请时间
        startBillPackTime = startTime;
        endBillPackTime = endTime;
      } else if (this.timeType == "2") {
        // 签收时间
        startSignForTime = startTime;
        endSignForTime = endTime;
      } else {
        // 打包完成时间
        startFinishPackTime = startTime;
        endFinishPackTime = endTime;
      }

      let param = {
        pageStart: this.pageNum,
        pageTotal: this.size,
        billPackNumbers: courierNumberList,
        outStockNumbers: number5,
        channelNumbers: channelNumbers,
        channelTransportId: this.queryData.channelTransportId,
        invitationCode: this.queryData.invitationCode,
        memberId: this.queryData.memberId,
        packStatus: this.queryData.packStatus,
        startBillPackTime: startBillPackTime,
        endBillPackTime: endBillPackTime,
        startFinishPackTime: startFinishPackTime,
        endFinishPackTime: endFinishPackTime,
        startSignForTime: startSignForTime,
        endSignForTime: endSignForTime,
      };
      Api.getPackPackagetListV2(param).then((res) => {
        if (res.data.status === "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    queryBtn_ok1() {
      this.pageStart = 1;
      this.packPackageDescs();
    },
    queryBtn_ok() {
      //  查询按钮
      this.pageNum = 1;
      this.getData();
    },

    allow() {
      //  允许打包
      if (this.singleData.length >= 1) {
        this.$confirm("是否允许此数据打包?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          let parm = new Object();
          let billPackId = new Array();
          for (var i = 0; i < this.singleData.length; i++) {
            billPackId.push(this.singleData[i].billPackId);
          }
          parm.billPackId = billPackId;
          let sign = tools.getSign(parm);
          parm.sign = sign;
          Api.allowPackage(parm).then((res) => {
            if (res.data.status === "success") {
              this.$message.success(res.data.message);
              this.getData();
            }
          });
        });
      } else {
        this.$message.warning("请z至少勾选一条数据");
      }
    },

    suspend() {
      //  暂停打包
      if (this.singleData.length >= 1) {
        this.$confirm("是否暂停此条数据打包?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          let parm = new Object();
          let billPackId = new Array();
          for (var i = 0; i < this.singleData.length; i++) {
            billPackId.push(this.singleData[i].billPackId);
          }
          parm.billPackId = billPackId;
          let sign = tools.getSign(parm);
          parm.sign = sign;
          Api.stopPackage(parm).then((res) => {
            if (res.data.status === "success") {
              this.$message.success(res.data.message);
              this.getData();
            }
          });
        });
      } else {
        this.$message.warning("请至少勾选一条数据");
      }
    },
    //取消打包申请
    rejection() {
      let singleData = this.singleData;
      let _this = this;
      if (singleData.length == 1) {
        this.$confirm("是否确定取消打包?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let param = new Object();
          param.billPackId = singleData[0].billPackId;
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.cancelPack(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success("取消申请成功");
              _this.getData();
            }
          });
        });
      } else {
        this.$message.warning("请勾选一条数据");
      }
    },
    closeBtn_7() {
      this.newForm7 = {
        courierCode: "",
        customerName: "",
        trackingNumber: "",
      };
    },
    // 保存派件确认
    confirmBtn_7() {
      let that = this;
      let param = this.newForm7;
      param.sign = tools.getSign(param);
      if (this.type51 == 1) {
        Api.create51TrackOrder(param).then((res) => {
          this.$message.success(res.data.message || "创建51Track订单成功");
          setTimeout(() => {
            that.dislogShow_7 = false;
            that.getData();
          }, 500);
        });
      } else if (this.type51 == 2) {
        Api.get51Track(param).then((res) => {
          this.$message.success(res.data.message || "获取51Track物流轨迹成功");
          setTimeout(() => {
            that.dislogShow_7 = false;
            that.getData();
          }, 500);
        });
      }
    },
    //创建
    rejection1() {
      let singleData = this.singleData;
      let _this = this;
      if (singleData.length == 1) {
        this.type51 = 1;
        this.title7 = "创建51Track订单";
        this.newForm7.trackingNumber = singleData[0].channelNumber;
        this.newForm7.customerName = singleData[0].fullName;
        this.dislogShow_7 = true;
      } else {
        this.$message.warning("请勾选一条数据");
      }
    },
    //获取物流轨迹
    rejection2() {
      let singleData = this.singleData;
      if (singleData.length == 1) {
        this.type51 = 2;
        this.title7 = "获取51Track物流轨迹";
        this.newForm7.trackingNumber = singleData[0].channelNumber;
        this.newForm7.customerName = singleData[0].fullName;
        this.dislogShow_7 = true;
      } else {
        this.$message.warning("请勾选一条数据");
      }
    },
    NewReject() {
      //  新建打包申请
      this.$router.push({
        path: "/BusinessManagement/Pickingconfirmation",
      });
    },

    returnGoods() {
      //  修改
      if (this.singleData.length === 1) {
        this.$router.push({
          path: "/BusinessManagement/Pickingconfirmation",
          query: {
            id: this.singleData[0].billPackId,
            memberId: this.singleData[0].memberId,
            nickname: this.singleData[0].nickname,
            number: this.singleData[0].packNumber,
          },
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },
    resetFormQ(num) {
      this.number1 = "";
      if (num === 1) {
        this.queryData = {
          packStartTime: "",
          packEndTime: "",
          channelTransportId: "",
          storageId: "",
          packStatus: "",
          memberId: "",
        };
        this.time = [];
      } else if (num === 2) {
        this.queryDetails = {
          courierNumber: "",
          billPackNumber: "",
          memberId: "",
          teamNumber: "",
          storageId: "",
          consigneeName: "",
          packageStatus: "",
          billPackStartTime: "",
          billPackEndTime: "",
        };
        this.time2 = [];
      }
    },
    //    ---------  申请打包明细  --------

    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storageIdArr = res.data.result;
        } else {
          console.log(`${res.data.message} --- 仓库下拉`);
        }
      });
    },

    getStatusValList() {
      //  获取包裹状态
      Api.getStatusValList({
        tableAndFieldName: "bill_pack_v1.pack_status",
      }).then((res) => {
        if (res.data.status === "success") {
          this.packageStatusArr = res.data.result;
        } else {
          console.log(`${res.data.message} --- 获取包裹状态`);
        }
      });
    },

    packPackageDescs() {
      let courierNumberList =
        tools.getOrderNum(this.queryDetails.courierNumber) || [];
      let billPackNumberList =
        tools.getOrderNum(this.queryDetails.billPackNumber) || [];
      // let params = JSON.parse(JSON.stringify(this.queryDetails));

      let paramss = {
        billPackEndTime: this.queryDetails.billPackEndTime,
        billPackNumber: billPackNumberList,
        billPackStartTime: this.queryDetails.billPackStartTime,
        consigneeName: this.queryDetails.consigneeName,
        courierNumber: courierNumberList,
        memberId: this.queryDetails.memberId,
        packageStatus: this.queryDetails.packageStatus,
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
        storageId: this.queryDetails.storageId,
        teamNumber: this.queryDetails.teamNumber,
      };
      Api.packPackageDescs(paramss).then((res) => {
        if (res.data.status === "success") {
          this.className1 = res.data.result.className || "";
          this.tableData1 = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;
          this.fetTableHeight();
          console.log(this.tableData1);
          console.log("this.tableData1");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status === "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        if (this.pageGroupName == "ApplicationPackaging") {
          for (var i = 0; i < this.myTableHeard.length; i++) {
            if (this.myTableHeard[i].isShow) {
              fieldNames.push(this.myTableHeard[i].field);
              heads.push(this.myTableHeard[i].name);
            }
          }
        }
        if (this.pageGroupName == "ApplicationPackaging2") {
          for (var i = 0; i < this.myTableHeard1.length; i++) {
            if (this.myTableHeard1[i].isShow) {
              fieldNames.push(this.myTableHeard1[i].field);
              heads.push(this.myTableHeard1[i].name);
            }
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let className;
      let that = this;
      let datas = [];
      if (this.pageGroupName == "ApplicationPackaging") {
        datas = that.tableData;
        className = that.className;
      } else {
        console.log(that.claimedData);
        datas = that.tableData1;
        className = that.className1;
      }
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      let array = {
        customizedExportId: customizedExportId,
        className: className,
        data: JSON.stringify(datas),
        sign: signs_e,
      };
      Api.templateDataExport(array).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];

      if (this.pageGroupName == "ApplicationPackaging") {
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldName.push(this.myTableHeard[i].field);
            head.push(this.myTableHeard[i].name);
          }
        }
      }
      if (this.pageGroupName == "ApplicationPackaging2") {
        for (var i = 0; i < this.myTableHeard1.length; i++) {
          if (this.myTableHeard1[i].isShow) {
            fieldName.push(this.myTableHeard1[i].field);
            head.push(this.myTableHeard1[i].name);
          }
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          if (this.pageGroupName == "ApplicationPackaging") {
            tools.dynamicExport(
              this.myTableHeard,
              this.className,
              this.tableData,
              "申请打包列表"
            );
          } else {
            tools.dynamicExport(
              this.myTableHeard1,
              this.className1,
              this.tableData1,
              "申请打包明细列表"
            );
          }
        }
      });
    },
    // ===============================================导出部分=结束======

    //   ------------- 设置表格动态高度  ------------

    resetHeight() {
      // 重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },

    fetTableHeight() {
      // 设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },

    getHeight() {
      // 获取表格高度
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 30; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
        if (this.activeName === "second") {
          queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
        }
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 130; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },

    //顶部标签页切换
    handleClick() {
      if (this.activeName === "second") {
        this.pageGroupName = "ApplicationPackaging2";
        this.SignInRecord();
        this.packPackageDescs();
      } else {
        this.pageGroupName = "ApplicationPackaging";
        this.getGroupFindFieldSort();
      }
    },
    //签收记录表头获取
    SignInRecord() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard1 = JSON.parse(arr[0].sortObject);
            this.sortName1 = arr[0].sortName;
          }
        } else {
          that.myTableHeard1 = that.tabelHeadeTitle1;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard1.length; i++) {
          if (that.myTableHeard1[i].isTotal) {
            totalArr.push(that.myTableHeard1[i].field);
          }
        }
        that.totalArr1 = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },

    myModel() {
      // 设置模板按钮
      this.show_sortTableHeard = true;
      this.tabelHeadeTitle = JSON.parse(JSON.stringify(this.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < this.tabelHeadeTitle.length; i++) {
        if (this.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      this.selectLength = selectLength;
      if (selectLength === this.tabelHeadeTitle.length) {
        this.allCheck = true;
      } else {
        this.allCheck = false;
      }
    },

    myModel1() {
      //  设置模板按钮
      let that = this;
      that.show_sortTableHeard1 = true;
      that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength === that.tabelHeadeTitle1.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.singleData = e;
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    //关闭弹窗
    closeDias1(e) {
      this.show_sortTableHeard1 = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    // 排序==>取消按钮(签收记录)
    cancelSortHeard1() {
      this.show_sortTableHeard1 = false;
      this.$nextTick(() => {
        this.$refs.mytable1.doLayout();
      });
    },
    // 排序==>确认按钮(签收记录)
    confirmOk1(e) {
      this.tabelHeadeTitle1 = e;

      this.myTableHeard1 = this.tabelHeadeTitle1;
      let sortObject = this.myTableHeard1;
      let signs = [
        {
          key: "sortName",
          val: this.sortName1, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName1, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard1 = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard1 = false;
    },
    // 点击某一项
    checktab1(idx, e) {
      let that = this;
      that.tabelHeadeTitle1[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength == that.tabelHeadeTitle1.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },
    //全选
    checkAll1(e) {
      let that = this;
      this.allCheck1 = e;
      for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
        this.tabelHeadeTitle1[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
    },
    // 合计
    getSummaries1(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr1.length; i++) {
          if (column.property == that.totalArr1[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable1.doLayout();
      });
      return sums;
    },

    //分页(申请打包)
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },

    //分页(打包明细)
    handleSizeChange2(val) {
      this.pageTotal = val;
      this.packPackageDescs();
    },
    handleCurrentChange2(val) {
      this.pageStart = val;
      this.packPackageDescs();
    },
    // // 上一页
    // handlePrevPage() {
    //     this.pageNum = this.pageNum - 1 || 1;
    //     this.getData();
    // },
    // // 下一页
    // handleNextPage() {
    //     this.pageNum = this.pageNum + 1 || 1;
    //     this.getData();
    // },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.inpustyle {
  width: 400px;
}

.addbut {
  margin-left: 20px;
}

.headebut {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.btnList1 {
  margin: 5px 0 0;

  /deep/ .el-button,
  .el-dropdown {
    margin: 0 10px 6px 0;
    min-width: 64px;
  }
}

.wtl_btnList1 {
  position: relative;

  .wtl_queryBoxNew {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
}

.diaBox {
  text-align: center;

  .textareaBox {
    display: flex;
    margin-bottom: 20px;

    .labelClass {
      width: 80px;
      text-align: right;
      margin-right: 10px;
      height: 40px;
      line-height: 40px;
    }

    // .textareaInput {
    //   flex: 1;
    // }
  }
}

.tableB {
  margin: 6px 0;
  padding: 10px 20px;
  background-color: #fff;
}

.endFooter {
  background-color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
}

.QXXJ_Container {
  /deep/ .el-dialog__body {
    padding: 0 !important;
    background-color: #f0f0f0;
  }
}

.tips {
  font-size: 16px;
  background-color: #fff;
  padding: 20px;

  .msg {
    margin-bottom: 6px;
  }

  .numbox {
    display: flex;

    div:nth-child(2) {
      background-color: #f0f0f0;
      padding: 0 6px;
      border: 1px #5d5d5d solid;
    }
  }
}

.titlebox {
  margin: 10px auto;
  width: 80%;
  font-weight: bold;
  text-align: center;
}
</style>
